import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3733b904&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=3733b904&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3733b904",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\work\\yunjiaohui\\tt321-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3733b904')) {
      api.createRecord('3733b904', component.options)
    } else {
      api.reload('3733b904', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=3733b904&scoped=true", function () {
      api.rerender('3733b904', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/other/dynamicMeta/index.vue"
export default component.exports