import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/auth/user/index',
    method: 'get',
    params,
  })
}

export function doEdit(data) {
  return request({
    url: '/auth/user/edit',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/auth/user/delete',
    method: 'post',
    data,
  })
}

export function changePwd(data) {
  return request({
    url: '/auth/user/change-pwd',
    method: 'post',
    data,
  })
}
