var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "demo",
    },
    [
      _c(
        "div",
        { staticClass: "demo__body" },
        [
          _c("el-tree", {
            ref: "menuTree",
            attrs: {
              data: _vm.menuTreeData,
              "default-checked-keys": _vm.menuTreeIds,
              "default-expand-all": "",
              "highlight-current": "",
              "node-key": "ID",
              "check-strictly": true,
              "check-on-click-node": true,
              props: _vm.menuDefaultProps,
              "show-checkbox": "",
            },
            on: { check: _vm.nodeChange, "check-change": _vm.checkChange },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "demo__footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "fl-right",
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.relation },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }