import request from '@/utils/request'

export function getRoleList(params) {
  return request({
    url: '/auth/authority/index',
    method: 'get',
    params,
  })
}

export function doEdit(data) {
  return request({
    url: '/auth/authority/create',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/auth/authority/delete',
    method: 'post',
    data,
  })
}
