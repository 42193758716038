var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            inline: true,
            "label-width": "140px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "类型" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("菜单")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("按钮")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "父级菜单", prop: "parentId" } },
            [
              _c("el-cascader", {
                attrs: {
                  options: _vm.menuList,
                  props: {
                    emitPath: false,
                    checkStrictly: true,
                    label: "name",
                    value: "ID",
                  },
                  clearable: "",
                  "show-all-levels": false,
                },
                model: {
                  value: _vm.form.parentId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "parentId", $$v)
                  },
                  expression: "form.parentId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.form.type == 1 ? "name" : "标识",
                prop: "name",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.type == 1,
                  expression: "form.type == 1",
                },
              ],
              attrs: { label: "路径", prop: "path" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.path,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "path", $$v)
                  },
                  expression: "form.path",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.type == 1,
                  expression: "form.type == 1",
                },
              ],
              attrs: { label: "vue文件路径", prop: "component" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.component,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "component", $$v)
                  },
                  expression: "form.component",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.type == 1,
                  expression: "form.type == 1",
                },
              ],
              attrs: { label: "重定向", prop: "redirect" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.redirect,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "redirect", $$v)
                  },
                  expression: "form.redirect",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.meta.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "title", $$v)
                  },
                  expression: "form.meta.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.type == 1,
                  expression: "form.type == 1",
                },
              ],
              attrs: { label: "图标", prop: "remixIcon" },
            },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    "popper-class": "icon-selector-popper",
                    trigger: "hover",
                    width: "292",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { slot: "reference" },
                    slot: "reference",
                    model: {
                      value: _vm.form.meta.remixIcon,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.meta, "remixIcon", $$v)
                      },
                      expression: "form.meta.remixIcon",
                    },
                  }),
                  _c("icon-table", { on: { "handle-icon": _vm.handleIcon } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "序号", prop: "sort" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "隐藏", prop: "hidden" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.hidden,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "hidden", $$v)
                  },
                  expression: "form.hidden",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.type == 1,
                  expression: "form.type == 1",
                },
              ],
              attrs: { label: "始终显示当前节点", prop: "alwaysShow" },
            },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.alwaysShow,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "alwaysShow", $$v)
                  },
                  expression: "form.alwaysShow",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.type == 1,
                  expression: "form.type == 1",
                },
              ],
              attrs: { label: "自定义svg图标", prop: "isCustomSvgIcon" },
            },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.meta.isCustomSvgIcon,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "isCustomSvgIcon", $$v)
                  },
                  expression: "form.meta.isCustomSvgIcon",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }