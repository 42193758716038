<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="60%"
    @close="close"
  >
    <el-form
      ref="form"
      :inline="true"
      label-width="140px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="类型">
        <el-radio-group v-model="form.type">
          <el-radio :label="1">菜单</el-radio>
          <el-radio :label="2">按钮</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="父级菜单" prop="parentId">
        <el-cascader
          v-model="form.parentId"
          :options="menuList"
          :props="{ emitPath:false,checkStrictly:true, label: 'name', value: 'ID' }"
          clearable
          :show-all-levels="false"
        />
      </el-form-item>
      <el-form-item :label="form.type == 1 ? 'name' : '标识'" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item v-show="form.type == 1" label="路径" prop="path">
        <el-input v-model="form.path" />
      </el-form-item>
      <el-form-item
        v-show="form.type == 1"
        label="vue文件路径"
        prop="component"
      >
        <el-input v-model="form.component" />
      </el-form-item>
      <el-form-item v-show="form.type == 1" label="重定向" prop="redirect">
        <el-input v-model="form.redirect" />
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.meta.title" />
      </el-form-item>
      <el-form-item v-show="form.type == 1" label="图标" prop="remixIcon">
        <el-popover
          popper-class="icon-selector-popper"
          trigger="hover"
          width="292"
        >
          <el-input slot="reference" v-model="form.meta.remixIcon" />
          <icon-table @handle-icon="handleIcon" />
        </el-popover>
      </el-form-item>
      <el-form-item label="序号" prop="sort">
        <el-input v-model="form.sort" />
      </el-form-item>
      <!--      <el-form-item label="badge" prop="badge">-->
      <!--        <el-input v-model="form.meta.badge" />-->
      <!--      </el-form-item>-->
      <el-form-item label="隐藏" prop="hidden">
        <el-switch v-model="form.hidden" />
      </el-form-item>
      <el-form-item
        v-show="form.type == 1"
        label="始终显示当前节点"
        prop="alwaysShow"
      >
        <el-switch v-model="form.alwaysShow" />
      </el-form-item>
      <el-form-item
        v-show="form.type == 1"
        label="自定义svg图标"
        prop="isCustomSvgIcon"
      >
        <el-switch v-model="form.meta.isCustomSvgIcon" />
      </el-form-item>
      <!--      <el-form-item label="固定" prop="affix">-->
      <!--        <el-switch v-model="form.meta.affix" />-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="无缓存" prop="noKeepAlive">-->
      <!--        <el-switch v-model="form.meta.noKeepAlive" />-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="不显示多标签页" prop="tagHidden">-->
      <!--        <el-switch v-model="form.meta.tagHidden" />-->
      <!--      </el-form-item>-->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import IconTable from '@/extra/VabIconSelector'
  import { doEdit } from '@/api/auth/menuManagement'

  export default {
    name: 'MenuManagementEdit',
    components: { IconTable },
    props:{
      menuList: {
        type: Array,
        default:new Array(),
      }
    },
    data() {
      return {
        form: {
          hidden: false,
          alwaysShow: false,
          component: '',
          path: '',
          type: 1,
          meta: {
            remixIcon: '',
            parentId: '',
            isCustomSvgIcon: false,
          },
        },
        rules: {
          name: [{ required: true, trigger: 'blur', message: '请输入name' }],
          // path: [{ required: true, trigger: 'blur', message: '请输入path' }],
          // component: [
          //   { required: true, trigger: 'blur', message: '请输入component' },
          // ],
          'meta.title': [
            { required: true, trigger: 'blur', message: '请输入标题' },
          ],
          // 'meta.remixIcon': [
          //   { required: true, trigger: 'blur', message: '请选择图标' },
          // ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      handleIcon(item) {
        this.form.meta.remixIcon = item
      },
      addSun(row) {
        this.title = '添加子菜单'
        console.log(row.ID)
        this.form.parentId = row.ID
        this.dialogFormVisible = true
      },
      showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.form.parentId = ''
        } else {
          this.title = '编辑'
          this.form = Object.assign({ edit: '1' }, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            console.log(this.form)
            const { msg } = await doEdit(this.form)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
