


import Vue from "vue"


Vue.directive('has',{
  inserted:function(el,binding,vnode){
  console.log(el,binding,)
    if(vnode.context.$route.meta.butpermissions[binding.value]){
      console.log(vnode.context.$route.meta.butpermissions[binding.value])
    }else{
      el.parentNode.removeChild(el)
    }

  }})

