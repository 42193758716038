var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menuManagement-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { lg: 24, md: 24, sm: 24, xl: 24, xs: 24 } },
            [
              _c(
                "vab-query-form",
                [
                  _c(
                    "vab-query-form-top-panel",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-plus", type: "primary" },
                          on: { click: _vm.handleEdit },
                        },
                        [_vm._v(" 添加 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: {
                    border: "",
                    data: _vm.list,
                    "default-expand-all": "",
                    "row-key": "path",
                    "tree-props": {
                      children: "children",
                      hasChildren: "hasChildren",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "left",
                      label: "标题",
                      prop: "meta.title",
                      "show-overflow-tooltip": "",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "name",
                      prop: "name",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "路径",
                      prop: "path",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "类型",
                      "show-overflow-tooltip": "",
                      width: "60",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(row.type == 1 ? "菜单" : "按钮") +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "隐藏",
                      "show-overflow-tooltip": "",
                      width: "60",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(row.hidden ? "是" : "否") + " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "vue文件路径",
                      prop: "component",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "重定向",
                      prop: "redirect",
                      "show-overflow-tooltip": "",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "图标",
                      "show-overflow-tooltip": "",
                      width: "70",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.meta
                              ? _c(
                                  "span",
                                  [
                                    row.meta.remixIcon
                                      ? _c("vab-remix-icon", {
                                          attrs: { icon: row.meta.remixIcon },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "操作",
                      "show-overflow-tooltip": "",
                      width: "140",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAdd(row)
                                  },
                                },
                              },
                              [_vm._v("子菜单")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("edit", {
        ref: "edit",
        attrs: { menuList: _vm.menuitem },
        on: { "fetch-data": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }