<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <!--      <el-form-item label="角色码" prop="role">-->
      <!--        <el-input v-model="form.role" />-->
      <!--      </el-form-item>-->
      <el-form-item label="角色名称" prop="role">
        <el-input v-model="form.authorityName" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { doEdit } from '@/api/auth/roleManagement'

  export default {
    name: 'RoleManagementEdit',
    data() {
      return {
        form: { edit: 0 },
        rules: {
          authorityName: [
            { required: true, trigger: 'blur', message: '请输入角色名' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.form.edit = 0
        } else {
          this.title = '编辑'
          this.form = Object.assign({}, row)
          this.form.edit = 1
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await doEdit(this.form)
            this.$baseMessage(msg, 'success', false, 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
