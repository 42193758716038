import router from '@/router'
import { getAction } from '@/api/request'
import Vue from 'vue'
import { pingInterval, wss } from '@/config'
import store from '@/store'
import { EventBus } from '@/utils/event-bus'

export default class WebSocketManager {
  constructor(url = wss, receiveMessageCallback = null) {
    this.socket = null // WebSocket 对象
    this.pingTimeout = null // 心跳计时器
    this.reconnectTimeout = null
    this.pingInterval = pingInterval
    this.reconnectTimeoutVal = 5000 // 重连间隔，单位：毫秒
    this.maxReconnectAttempts = 10 // 最大重连尝试次数
    this.reconnectAttempts = 0 // 当前重连尝试次数
    this.url = url // WebSocket 连接地址
    this.receiveMessageCallback = receiveMessageCallback // 接收消息回调函数
    this.start()
  }

  start() {
    if (this.url) {
      this.connectWebSocket()
    } else {
      console.error('WebSocketManager error: Please provide a valid URL.')
    }
  }

  connectWebSocket() {
    this.socket = new WebSocket(this.url)
    this.setupEventListeners()
  }

  setupEventListeners() {
    this.socket.addEventListener('open', this.onOpen.bind(this))
    this.socket.addEventListener('message', this.receiveMessage.bind(this))
    this.socket.addEventListener('close', this.handleClose.bind(this))
    this.socket.addEventListener('error', this.handleError.bind(this))
  }
  onOpen() {
    console.log('WebSocket connected!')
    this.startHeartbeat() // Restart the heartbeat after successful connection/reconnection
    clearTimeout(this.reconnectTimeout) // Clear any pending reconnect timeouts
    this.reconnectAttempts = 0 // Reset reconnect attempts count
  }
  startHeartbeat() {
    this.pingTimeout = setInterval(
      () => this.sendMessage('ping'),
      this.pingInterval
    )
  }

  sendMessage(message) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message)
    } else {
      console.error(
        'WebSocketManager error: Connection is not open to send message.'
      )
      // 如果需要，这里可以触发重连逻辑，但需谨慎以避免循环尝试
    }
  }

  receiveMessage(event) {
    if (typeof this.receiveMessageCallback === 'function') {
      this.receiveMessageCallback(event.data)
    }
  }

  handleClose(event) {
    console.log('WebSocket closed:', event.code, event.reason)
    clearInterval(this.pingTimeout)

    if (!event.wasClean && event.code !== 1000) {
      // 1000 is normal closure, no need to reconnect
      this.attemptReconnect()
    }
  }

  handleError(event) {
    console.error('WebSocket error:', event)
    this.attemptReconnect()
  }

  attemptReconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      console.log('Attempting to reconnect...')
      this.reconnectAttempts++
      this.reconnectTimeout = setTimeout(
        this.connectWebSocket.bind(this),
        this.reconnectTimeoutVal
      )
    } else {
      console.error('Max reconnect attempts reached. Unable to reconnect.')
      this.reconnectAttempts = 0 // Reset for future potential use
    }
  }

  // 新增的关闭连接方法
  closeWebSocket() {
    if (this.socket) {
      this.socket.close(1000, 'Normal closure') // 1000 is the standard code for normal closure
      clearInterval(this.pingTimeout)
      clearTimeout(this.reconnectTimeout)
      this.socket.removeEventListener('open', this.startHeartbeat)
      this.socket.removeEventListener('message', this.receiveMessage)
      this.socket.removeEventListener('close', this.handleClose)
      this.socket.removeEventListener('error', this.handleError)
      this.socket = null
      this.reconnectAttempts = 0
    }
  }
}

/**
 * 接收消息回调
 * 使用方式
 */
const commonreceiveMessage = (res) => {
  console.log('接收消息回调：', res)
  const data = JSON.parse(res)
  if (data.type) {
    switch (data.type) {
      case 1:
        // reMessage(data)
        const message = JSON.stringify({
          type: 'bind',
          login_type: 1,
          user_id: localStorage.getItem('userid')
        })
        socketManager.sendMessage(message)
        break;
      case 2:
        // console.log('接收消息回调：',data)
        Vue.prototype.$notify({
          title: data.title,
          message: data.desc,
          type: 'success',
          duration: 0,
        })
        break
      case 3:
        Vue.prototype.$message({
            type: 'success',
            message: '文件正在生成中，请稍后点击右上角"消息通知"查看并点击下载~',
        })
        EventBus.$emit('socket',data.obj)
        break;
      default:
        break
    }
  }
}

let socketManager = null

router.beforeEach(async (to, from, next) => {
  if (to.fullPath == '/register' || to.fullPath.indexOf('/login') != -1) {
    if (socketManager) {
      // 断开ws
      console.log('断开ws')
      socketManager.closeWebSocket()
      socketManager = null
    }
  } else {
    if (!socketManager) {
      if (store.getters['user/token'] || store.state['user/token']) {
        console.log('创建ws', store.getters['user/token'])
        socketManager = new WebSocketManager(wss, commonreceiveMessage)
      } else {
        store.subscribe(async (mutation, state) => {
          console.log(mutation, 'vuex订阅', state)
          if (mutation.type == 'user/setToken' && mutation.payload) {
            socketManager = new WebSocketManager(wss, commonreceiveMessage)
          }
        })
      }
      // console.log(store.getters['user/token'])
    }
  }
  next()
})

const reMessage = async (data) => {
  await getAction('/api/system/workerman-push/bind', {
    client_id: data.client_id,
  }).then((res) => {
    if (res.request_code == 200) {
      console.log('绑定成功', res)
    } else {
      console.log('绑定失败')
      reMessage(data)
    }
  })
}
