<template>
  <div v-loading="loading" class="demo">
    <div class="demo__body">
      <el-tree
        ref="menuTree"
        :data="menuTreeData"
        :default-checked-keys="menuTreeIds"
        default-expand-all
        highlight-current
        node-key="ID"
        :check-strictly="true"
        :check-on-click-node="true"
        :props="menuDefaultProps"
        show-checkbox
        @check="nodeChange"
        @check-change="checkChange"
      />
    </div>
    <div class="demo__footer">
      <el-button class="fl-right" size="small" type="primary" @click="relation">
        确 定
      </el-button>
    </div>
  </div>
</template>
<script>
  import {
    addMenuAuthority,
    getMenuAuthority,
    getTree,
  } from '@/api/auth/menuManagement'

  export default {
    name: 'Menus',
    props: {
      row: {
        default: function () {
          return {}
        },
        type: Object,
      },
    },
    data() {
      return {
        loading: false,
        checkTree: [],
        menuTreeData: [],
        menuTreeIds: [],
        needConfirm: false,
        menuDefaultProps: {
          children: 'children',
          label: function (data) {
            return data.meta.title
          },
        },
      }
    },
    created() {},
    async mounted() {
      this.loading = true
      const res = await getTree()
      this.menuTreeData = res.data
      const res1 = await getMenuAuthority({ authorityId: this.row.authorityId })
      const menus = res1.data
      const arr = []
      console.log(menus, '-------------')

      menus.map((item) => {
        // 防止直接选中父级造成全选
        if (!menus.some((same) => same.parentId === item.menuId)) {
          console.log(item.menuId)
          arr.push(Number(item.menuId))
        }
      })
      // element-ui el-tree数据回显问题
      // https://blog.csdn.net/jasmine0178/article/details/103600508
      arr.forEach((value) => {
        this.$refs.menuTree.setChecked(value, true, false)
      })
      // this.menuTreeIds = arr
      this.loading = false
    },
    methods: {
      handleClose() {},
      checkChange(val, checked) {
        console.log(val.ID)
        console.log(checked)
        // if (checked) {
        //   console.log('push-' + val.ID)
        //   this.checkTree.push(val.ID)
        // } else {
        //   console.log('pop-' + val.ID)
        //   this.checkTree.pop(val.ID)
        // }
        // console.log(this.checkTree)
      },
      nodeChange() {
        // console.log(val)
        //
        // this.checkTree.push(val.ID)
        // console.log(this.checkTree)
        // this.needConfirm = true
        // var aKey = this.getCheckedKeys(
        //   this.menuTreeData,
        //   this.$refs.menuTree.getCheckedKeys(),
        //   'ID'
        // )
        // console.log(aKey)
      },
      // 暴露给外层使用的切换拦截统一方法
      enterAndNext() {
        this.relation()
      },
      // 关联树 确认方法
      async relation() {
        //const checkArr = this.$refs.menuTree.getCheckedNodes(false, true)
        //console.log(checkArr)
        const res = await addMenuAuthority({
          menus: this.$refs.menuTree
            .getCheckedKeys()
            .concat(this.$refs.menuTree.getHalfCheckedKeys()),
          authorityId: this.row.authorityId,
        })
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '菜单权限设置成功!',
          })
        } else {
          this.$message({
            type: 'error',
            message: '菜单权限设置失败!',
          })
        }
      },
    },
  }
</script>

<style scoped>
  .el-drawer__header {
    margin-bottom: 12px;
  }

  .demo {
    height: 100%;
    padding-bottom: 70px;
    overflow: auto;
  }

  .demo__body {
    box-sizing: border-box;
    flex: auto;
    height: 100%;
    padding: 0px 20px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .demo__footer {
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    flex: none;
    width: 100%;
    padding: 10px 20px;
    background: #f9f8f8;
    border: 1px solid #ededed;
    box-shadow: 0 -1px 4px 1px rgba(0, 0, 0, 0.14);
  }
</style>
