import request from '@/utils/request'
import Vue from "vue"

export function postAction(url,param={}){
  return request({
    url:url,
    method:'POST',
    data:param
  })
}

export function getAction(url,param={}){
  return request({
    url:url,
    method:'GET',
    params:param
  })
}

export function uploadAction(url, paramr) {
  return request({
    url: url,
    data: paramr,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data', // 文件上传
    },
  })
}

/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url, parameter) {
  return request({
    url: url,
    data: parameter,
    method: "post",
    responseType: "blob",
  })
}

/**
 * 下载文件
 * @param url 文件路径
 * @param fileName 文件名
 * @param parameter
 * @returns {*}
 */
export function downloadFile(url, fileName='导出数据', parameter) {
  console.log(parameter, "导出用户数据")
  return downFile(url, parameter).then((data) => {
    console.log(data)
    if (!data || data.size === 0) {
      Vue.prototype["$message"].warning("文件下载失败")
      return
    }
    if (typeof window.navigator.msSaveBlob !== "undefined") {
      window.navigator.msSaveBlob(new Blob([data]), fileName)
    } else {
      let url = window.URL.createObjectURL(new Blob([data]))
      console.log("url", url)
      let link = document.createElement("a")
      link.style.display = "none"
      link.href = url
      link.setAttribute("download", fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) //下载完成移除元素
      window.URL.revokeObjectURL(url) //释放掉blob对象
    }
  })
}
