<template>
  <div class="role-management-container">
    <el-alert
      :closable="true"
      title="请谨慎删除角色，会造成不必要的错误信息"
      type="error"
    />
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
          添加
        </el-button>
        <el-button icon="el-icon-delete" type="danger" @click="handleDelete">
          批量删除
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.role"
              clearable
              placeholder="请输入角色"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" show-overflow-tooltip type="selection" />

      <el-table-column
        align="center"
        label="角色ID"
        prop="id"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="角色名称"
        prop="authorityName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="220"
      >
        <template #default="{ row }">
          <el-button size="mini" type="primary" @click="handleAuth(row)">
            权限
          </el-button>
          <el-button size="mini" type="primary" @click="handleEdit(row)">
            编辑
          </el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
    <el-drawer v-if="drawer" title="角色配置" :visible.sync="drawer">
      <Menus ref="menus" :row="activeRow" />
    </el-drawer>
  </div>
</template>

<script>
  import { doDelete, getRoleList } from '@/api/auth/roleManagement'
  import Edit from './components/RoleManagementEdit'
  import Menus from './components/menus'

  export default {
    name: 'RoleManagement',
    components: {
      Menus,
      Edit,
    },
    data() {
      return {
        drawer: false,
        activeRow: {},
        tableData: [],
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 0,
          pageSize: 10,
          role: '',
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleAuth(row) {
        console.log(row)
        this.drawer = true
        this.activeRow = row
      },
      autoEnter(activeName, oldActiveName) {
        const paneArr = ['menus']
        if (oldActiveName) {
          if (this.$refs[paneArr[oldActiveName]].needConfirm) {
            this.$refs[paneArr[oldActiveName]].enterAndNext()
            this.$refs[paneArr[oldActiveName]].needConfirm = false
          }
        }
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await doDelete({ ids: row.id })
            this.$baseMessage(msg, 'success')
            await this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const { msg } = await doDelete({ ids })
              this.$baseMessage(msg, 'success')
              await this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error')
            return false
          }
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 0
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount } = await getRoleList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.listLoading = false
      },
    },
  }
</script>
<style>
  .el-drawer__body {
    height: 100%;
    /*padding-bottom: 70px;*/
  }
</style>
