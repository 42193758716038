<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="用户名" prop="username">
        <el-input v-model.trim="form.username" />
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model.trim="form.password" type="password" />
      </el-form-item>
      <el-form-item label="姓名" prop="email">
        <el-input v-model.trim="form.truename" />
      </el-form-item>

      <el-form-item label="昵称" prop="email">
        <el-input v-model.trim="form.nickname" />
      </el-form-item>

      <el-form-item label="角色" prop="roles">
        <el-checkbox-group v-model="form.roles">
          <el-checkbox
            v-for="(role, index) in rolesList"
            :key="index"
            :checked="form.roles.includes(role.id)"
            :label="role.id"
          >
            {{ role.authorityName }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { doEdit } from '@/api/auth/userManagement'
  import { getRoleList } from '@/api/auth/roleManagement'

  export default {
    name: 'UserManagementEdit',
    data() {
      return {
        rolesList: [],
        form: {password:'',username:'',truename:'',nickname:'', edit: 0, roles: [] },
        rules: {
          username: [
            { required: true, trigger: 'blur', message: '请输入用户名' },
          ],
          nickname: [
            { required: true, trigger: 'blur', message: '请输入昵称' },
          ],
          truename: [
            { required: true, trigger: 'blur', message: '请输入真实姓名' },
          ],
          roles: [{ required: true, trigger: 'blur', message: '请选择角色' }],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {
      this.getRolesList()
    },
    methods: {
      async getRolesList() {
        console.log('test')
        const res = await getRoleList({ pageSize: 100 })
        console.log(res)
        if (res.code == 0) {
          this.rolesList = res.data
        }
      },
      showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.form.edit = 0
        } else {
          this.title = '编辑'
          this.form = Object.assign({}, row)
          this.form.edit = 1
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await doEdit(this.form)
            this.$baseMessage(msg, 'success', false, 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
